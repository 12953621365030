<template>
  <div class="class-list">
    <GlobalInfoBar
      title="支付设置"
      content="主要管理回收商家充值金额设置及体验赠送、单笔订单交易扣费等（扣费在回收商家确认收货时触发）"
    />
    <Info/>
  </div>
</template>

<script>
import Info from "./info";
export default {
  name: "class-list",
  components: {
    Info
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>